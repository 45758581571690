var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.disks
    ? _c(
        "div",
        { staticClass: "glances-disk-space-wrapper" },
        _vm._l(_vm.disks, function (disk, key) {
          return _c(
            "div",
            { key: key, staticClass: "disk-row" },
            [
              _c("PercentageChart", {
                attrs: {
                  title: disk.device_name,
                  values: [
                    {
                      label: _vm.$t("widgets.glances.disk-space-used"),
                      size: disk.percent,
                      color: "#f80363",
                    },
                    {
                      label: _vm.$t("widgets.glances.disk-space-free"),
                      size: 100 - disk.percent,
                      color: "#20e253",
                    },
                  ],
                },
              }),
              _c("p", { staticClass: "info" }, [
                _c("b", [
                  _vm._v(_vm._s(_vm.$t("widgets.glances.disk-space-free"))),
                ]),
                _vm._v(
                  ": " +
                    _vm._s(_vm._f("formatSize")(disk.size - disk.used)) +
                    " out of " +
                    _vm._s(_vm._f("formatSize")(disk.size)) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "info" }, [
                _c("b", [
                  _vm._v(_vm._s(_vm.$t("widgets.glances.disk-mount-point"))),
                ]),
                _vm._v(": " + _vm._s(disk.mnt_point)),
              ]),
              _c("p", { staticClass: "info" }, [
                _c("b", [
                  _vm._v(_vm._s(_vm.$t("widgets.glances.disk-file-system"))),
                ]),
                _vm._v(": " + _vm._s(disk.fs_type)),
              ]),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }